import cache from '@/plugins/cache'
import { defineStore } from 'pinia'
const useConfigStore = defineStore(
  'config',
  {
    state: () => ({
	  isSearch: cache.session.get('isSearch') || 1,//是否搜索状态(默认显示)
	  isFirstPopup: cache.local.get('isFirstPopup') || 1,//是否第一次显示弹窗(默认显示)
	  isLoaded: cache.local.get('isLoaded') || 0,//是否已经看过
	  isMobile: cache.session.get('isMobile'),//是否移动端
	  isLuoli: cache.session.get('isLuoli'),
	  googleId: '1028944982619-vu9n5a1urvn670tathmjjrt506uab6jh.apps.googleusercontent.com'
    }),
    actions: {
		setSearch(data) {
			this.isSearch = data;
			cache.session.set('isSearch',data)
		},
		setFristPopup(data) {
			this.isFirstPopup = data;
			cache.local.set('isFirstPopup',data)
		},
		setIsLoaded(data){
			this.isLoaded = data;
			cache.local.set('isLoaded',data)
		},
		setIsMobile(data){
			this.isMobile = data;
			cache.session.set('isMobile',data)
		},
		setIsLuoli(data){
			this.isLuoli = data;
			cache.session.set('isLuoli',data)
		}
    }
  })

export default useConfigStore

import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'
import vant from 'vant'
import 'vant/lib/index.css'
import './assets/styles/common.less'

require('swiper/swiper-bundle.css')
require("swiper/components/pagination/pagination.min.css")
require("swiper/components/navigation/navigation.min.css")

import plugins from './plugins'
import i18n from './lang/index.js'

import vue3GoogleLogin from 'vue3-google-login'

// import vConsole from './plugins/vconsole'
import './registerServiceWorker'

const app = createApp(App);
app.use(router)
app.use(store)
app.use(vant)
app.use(plugins)
app.use(i18n)
app.use(vue3GoogleLogin, {
  clientId: '1028944982619-vu9n5a1urvn670tathmjjrt506uab6jh.apps.googleusercontent.com'
})
app.mount('#app')
